import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  reduxForm,
  Form,
  Field,
  FormSection,
  FieldArray,
  arrayPush,
  getFormValues,
  getFormSyncErrors,
} from 'redux-form/immutable';
import { createStructuredSelector } from 'reselect';
import { productCodeCheck } from 'helpers/constants';
import { compose } from 'redux';
import { makeStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import { usePrevious } from 'utils/hooks';
import {
  Modal,
  Typography,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core';
import Search from 'icons/Search';
import Close from 'icons/Close';
import Add from 'icons/Add';
import Heart from 'icons/Heart';
import Minus from 'icons/Minus';
import { setAppLoadingAction } from 'containers/App/actions';
import { getValidFundssAction } from '../../../actions.js';
import { makeSelectValidFunds } from '../../../selectors.js';
//validate
import validate from './validate';
import { isEmpty } from 'lodash';
import { isEqual } from 'lodash/lang';
import { has } from 'lodash/object';
import { forEach, filter, map } from 'lodash/collection';
import { convertIfImmutable } from 'helpers/lang';
import FundList from './components/FundList.js';
import { FormattedMessage } from 'react-intl';
import messages from 'containers/ApplicationFormPage/messages.js';
import { formValues } from 'redux-form';

const useStyles = makeStyles(theme => ({
  btnAdd: {
    color: theme.palette.primary.dark,
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  borderBackground: {
    borderBottom: '1px solid #DDDFE0',
    padding: '10px 0',
  },
  backgroundModal: {
    width: '100%',
    height: '100%',
    backgroundColor: '#F2F2F2',
  },
  backgroundSearch: {
    backgroundColor: 'white',
    padding: '22px 30px',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.primary.dark,
    width: 30,
    height: 48,
  },
  search: {
    flexGrow: 0,
    maxWidth: '3%',
    flexBasis: '3%',
  },
  inputSearch: {
    flexGrow: 0,
    maxWidth: '94%',
    flexBasis: '94%',
  },
  customInput: {
    width: '96% !important',
    marginLeft: '2%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '0px !important',
      border: 'none !important',
    },
  },
  bodyModal: {
    padding: 30,
    overflowY: 'scroll',
    height: `calc(100% - 116px)`,
  },
  headerModal: {
    paddingRight: 20,
  },
  textTitle: {
    paddingBottom: 10,
    color: '#B6B8B4',
    fontSize: 14,
    fontWeight: 'bold',
  },
  textTitle2: {
    paddingBottom: 10,
    color: '#B6B8B4',
    fontSize: 14,
    fontWeight: 'bold',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  categoryTitle: {
    paddingBottom: 10,
    color: '#B6B8B4',
    fontSize: 14,
    fontWeight: 'bold',
    cursor: 'pointer',
    marginTop: '2rem',
  },
  textTitle2Selected: {
    paddingBottom: 10,
    color: '#B6B8B4',
    fontSize: 14,
    fontWeight: 'bold',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  menuTabsOuter: {
    borderBottom: '1px solid #B6B8B4',
  },
  menuTabs: { width: '80%' },
  textAdEur: {
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  },
  box: {
    flex: 1,
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 20,
    marginTop: 10,
    alignItems: 'center',
  },
  titleEuro: {
    color: theme.palette.primary.dark,
  },
  eur: {
    fontWeight: 'bold',
  },
  borderEurop: {
    borderBottom: '1px solid #B6B8B4',
    marginTop: 32,
  },
  iconHeart: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
  boxFavourites: {
    flex: 1,
    display: 'flex',
    backgroundColor: 'white',
    border: '1px solid #043594',
    padding: '0px 20px',
    marginTop: 10,
    alignItems: 'center',
    marginRight: 20,
  },
  inputFavourites: {
    marginTop: 10,
    '& .MuiFormControl-marginNormal': {
      marginBottom: 0,
    },
  },
  totalInvestment: {
    margin: 20,
    fontSize: 18,
  },
  totalPercent: {
    marginTop: 20,
    fontSize: 18,
  },
  btnIcon: {
    cursor: 'pointer',
    margin: '12px 0px',
  },
  colorBorder: {
    border: 'none',
  },
  focused: {
    '& .MuiInputBase-input:focus': {
      border: '2px solid #043594',
    },
  },
  error: {
    color: red[800],
    fontWeight: 600,
  },
  bold: {
    fontWeight: 800,
  },

}));

function Investment(props) {
  const {
    handleSubmit,
    applicationDetails,
    onSubmit,
    getValidFunds,
    validFunds,
    initialValues,
    addFund,
    formValues,
    formErrors,
    applicationId,
    setAppLoading,
    updateApplicationFunds,
    updateApplicationFunds2,
    fundsBelongToNexusOrDever,
    isInforce,
  } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [showEurop, setShowEurop] = useState(false);
  const [isFavourites, setIsFavourites] = useState(false);
  const [show, setShow] = useState('ALL');
  const [disabledFundList, setDisabledFundList] = useState(false);
  const [disabledStrategyList, setDisabledStrategyList] = useState(false);
  const [search, setSearch] = useState('');
  const [isStrategySelected, setIsStrategySelected] = useState(false);
  const prevFundlist = usePrevious(formValues?.FundList?.length);
  const parsedApplicationDetails = convertIfImmutable(applicationDetails);
  const [isASPFund, setIsASPFund] = useState(false);

  //  for Investment strategies page
  const isLapsePolicy = parsedApplicationDetails.productConfigs?.policyStatus == 'L' ;
  const investmentText = "Investment Strategy for Additional Single Premium";
  const isAsp = parsedApplicationDetails.productConfigs?.singlePremium > 0;
  const isFutura = parsedApplicationDetails.productName == 'Futura' || parsedApplicationDetails.productName == 'My Life Protect';

  const checkFund = () => {
    let filteredFundList = filter(formValues?.FundList, fund => {
      return !isEmpty(fund.fundCode);
    });

    if (filteredFundList?.FundList?.length > 0 || filteredFundList?.ASPFundList?.length) {
      updateApplicationFunds2({
        filteredFundList,
        applicationId,
      });
    }
  };

  useEffect(() => {
    if (!isEqual(prevFundlist, formValues?.FundList?.length)) {
      if (formValues?.FundList?.length > 0) {
        checkFund();
      }
    }
  }, [formValues?.FundList]);
  useEffect(() => {
    //SET THE STATUS OF THE LIST
    if (formValues.FundList?.length === 0) {
      setShow('ALL');
      setDisabledFundList(false);
      setDisabledStrategyList(false);
    }
    if (formValues.FundList?.length > 1) {
      if (has(formValues.FundList[0], 'fundCode')) {
        setShow('FUNDS');
        setDisabledFundList(false);
        setDisabledStrategyList(true);
      } else if ((formValues.FundList[0], 'autoStrategyCode')) {
        setShow('STRATEGIES');
        setDisabledFundList(true);
        setDisabledStrategyList(false);
      }
    }
  }, [formValues]);

  useEffect(() => {
    const data = { id: applicationId, searchText: '' };
    // getValidFunds(data);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const handleOpen = () => {
    setAppLoading(true);
    setOpen(true);
  };

  const setNotSelected = value => {
    setIsStrategySelected(value);
  };

  useEffect(() => {
    const data = { id: applicationId, searchText: '' };
    // getValidFunds(data);
  }, [open]);

  const handleSearch = () => {
    const data = { id: applicationId, searchText: search };
    getValidFunds(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleShowEurop = () => {
    setShowEurop(true);
  };

  const handleFavourites = fund => {
    setOpen(false);
    setIsFavourites(true);

    //get the type of list of the item
    const typeOfList = () => {
      if (has(fund, 'fundCode')) {
        return 'fund';
      } else if (has(fund, 'autoStrategyCode')) {
        return 'strategy';
      }
    };

    //check for the list type and make sure the list is the same as the item type
    if (typeOfList() === 'fund' && disabledFundList === false) {
      //change the state of the list
      setDisabledFundList(false);
      //make sure the other list is disabled
      setDisabledStrategyList(true);
      //change the list view
      setShow('FUNDS');
      if (
        !formValues.FundList ||
        (!formValues.FundList.some(item => item.fundCode === fund.fundCode) &&
          formValues.FundList?.length < 30)
      ) {
        addFund(fund, isASPFund);
      }
    } else if (typeOfList() === 'strategy' && disabledStrategyList === false) {
      //change the state of the list
      setDisabledStrategyList(false);
      //make sure the other list is disabled
      setDisabledFundList(true);
      //change the list view
      setShow('STRATEGIES');
      if (
        !formValues.FundList ||
        (!formValues.FundList.some(
          item => item.autoStrategyCode === fund.autoStrategyCode,
        ) &&
          formValues.FundList?.length < 30)
      ) {
        addFund(fund, isASPFund);
      }
    }
  };

  const totalPercentage = () => {
    let total = 0;
    let totalcheck = 0;
    forEach(formValues.FundList, fund => {
      totalcheck += fund.percent ? parseFloat(fund.percent) : 0;
    });

    total = Number.isInteger(totalcheck) ? parseInt(totalcheck) : parseFloat(totalcheck).toFixed(2);
    return total ? total : 0;
  };

  const totalASPPercentage = () => {
    let total = 0;
    let totalcheck = 0;
    forEach(formValues.ASPFundList, fund => {
      totalcheck += fund.percent ? parseFloat(fund.percent) : 0;
    });

    total = Number.isInteger(totalcheck) ? parseInt(totalcheck) : parseFloat(totalcheck).toFixed(2);
    return total ? total : 0;
  };



  // const keyPress = e => {
  //   var duration = 1000;
  //   clearTimeout(this.inputTimer);
  //   this.inputTimer = setTimeout(() => {
  //     handleSearch();
  //     this.updateInputValue(e.target.value);
  //   }, duration);
  //   if (e.keyCode === 13) {

  //   }
  // };

  // manipulate strategies
  let updatedStrategies = () => {
    let strategies = [];

    forEach(validFunds.stratagies, strat => {
      if (strat?.currencies.length > 0) {
        forEach(strat?.currencies, cur => {
          strategies.push({ ...strat, fundCcy: cur });
        });
      } else {
        strategies.push(strat);
      }
    });

    return strategies;
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>

      {(!isInforce || !isFutura) && (
        <>
          <Grid
          container
          item
          xs={12}
          md={10}
          lg={6}
          className={classes.borderBackground}
        >
          <Grid item xs={10}>
            {!(productCodeCheck(8, parsedApplicationDetails.productCode)) &&
              <Typography variant="body2" className={classes.bold}>
                {formValues.FundList && formValues.FundList?.length}/30 funds{' '}
                {show === 'FUNDS' &&
                  `(${validFunds.funds?.length - formValues.FundList?.length})`}
                {show === 'STRATEGIES' &&
                  `(${validFunds.stratagies?.length -
                  formValues.FundList?.length})`}
              </Typography>
            }
          </Grid>

          <Grid item xs={2}>
            <Typography
              className={classes.btnAdd}
              align="right"
              onClick={() => {
                 if(!isStrategySelected){
                  setIsASPFund(false);
                  handleOpen();
                 }
                }}
            >
              Add
            </Typography>
          </Grid>
          </Grid>
          <Grid container item xs={12} md={10} lg={6}>
            <FieldArray
              name="FundList"
              component={FundList}
              classes={classes}
              setDeleted={setNotSelected}
              checkFund={checkFund}


            />
          </Grid>

          {formValues.FundList?.length > 0 && (
            <Grid container item xs={12} md={10} lg={6}>
              <Grid item xs={10} align="right">
                <Typography className={classes.totalInvestment}>
                  Total % investment :
                </Typography>
              </Grid>
              <Grid item xs={2} align="right">
                <Typography className={classes.totalPercent}>
                  {totalPercentage()}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.error} align="right">
                  {formErrors.totalPercentage}
                </Typography>{' '}
              </Grid>
              <Grid item xs={12}>
                <br />
                {fundsBelongToNexusOrDever && (
                  <Typography variant="body1" className={classes.error}>
                    <FormattedMessage {...messages.fundError} />
                  </Typography>
                )}
              </Grid>
              {/* {isInforce && (
                <Grid item xs={12}>
                  <Typography variant="body1">
                    <FormattedMessage {...messages.investmentCaption} />
                  </Typography>
                </Grid>
              )} */}
            </Grid>
          )}
      </>) }

    

      {isInforce && isFutura && isLapsePolicy && (
        <>
          <Grid
          container
          item
          xs={12}
          md={10}
          lg={6}
          className={classes.borderBackground}
        >
          <Grid item xs={10}>
            {!(productCodeCheck(8, parsedApplicationDetails.productCode)) &&
              <Typography variant="body2" className={classes.bold}>
                {formValues.FundList && formValues.FundList?.length}/30 funds{' '}
                {show === 'FUNDS' &&
                  `(${validFunds.funds?.length - formValues.FundList?.length})`}
                {show === 'STRATEGIES' &&
                  `(${validFunds.stratagies?.length -
                  formValues.FundList?.length})`}
              </Typography>
            }
          </Grid>

          <Grid item xs={2}>
            <Typography
              className={classes.btnAdd}
              align="right"
              onClick={() => {
                if(!isStrategySelected){
                  setIsASPFund(false);
                  handleOpen();
                }
               }}
            >
              Add
            </Typography>
          </Grid>
          </Grid>
          <Grid container item xs={12} md={10} lg={6}>
            <FieldArray
              name="FundList"
              component={FundList}
              classes={classes}
              setDeleted={setNotSelected}
              checkFund={checkFund}

            />
          </Grid>

          {formValues.FundList?.length > 0 && (
            <Grid container item xs={12} md={10} lg={6}>
              <Grid item xs={10} align="right">
                <Typography className={classes.totalInvestment}>
                  Total % investment :
                </Typography>
              </Grid>
              <Grid item xs={2} align="right">
                <Typography className={classes.totalPercent}>
                  {totalPercentage()}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.error} align="right">
                  {formErrors.totalPercentage}
                </Typography>{' '}
              </Grid>
              <Grid item xs={12}>
                <br />
                {fundsBelongToNexusOrDever && (
                  <Typography variant="body1" className={classes.error}>
                    <FormattedMessage {...messages.fundError} />
                  </Typography>
                )}
              </Grid>
              {/* {isInforce && (
                <Grid item xs={12}>
                  <Typography variant="body1">
                    <FormattedMessage {...messages.investmentCaption} />
                  </Typography>
                </Grid>
              )} */}
            </Grid>
          )}
      </>) }

      {isInforce && isFutura && isLapsePolicy && isAsp && (
        <>
          <Typography
          variant="body1"
          style={{
            fontWeight: '700',
            margin: '40px 0',
            borderBottom: '1px solid rgba(0,0,0,0.12)',
            paddingBottom: '25px',
          }}
          >
            {investmentText}

          </Typography>

        </>
      )}

    {isInforce && isFutura && isAsp && (
        <>
          {/* Investment fund header and open button*/}
          <Grid
            container
            item
            xs={12}
            md={10}
            lg={6}
            className={classes.borderBackground}
          >
            <Grid item xs={10}>
              {!(productCodeCheck(8, parsedApplicationDetails.productCode)) &&
                <Typography variant="body2" className={classes.bold}>

                  {formValues.ASPFundList && formValues.ASPFundList?.length}/30 funds{' '}
                  {show === 'FUNDS' &&
                    `(${validFunds.funds?.length - formValues.ASPFundList?.length})`}
                  {show === 'STRATEGIES' &&
                    `(${validFunds.stratagies?.length -
                    formValues.ASPFundList?.length})`}
                </Typography>
              }
            </Grid>

            <Grid item xs={2}>
              <Typography
                className={classes.btnAdd}
                align="right"
                onClick={() => {
                  if(!isStrategySelected){
                    setIsASPFund(true);
                    handleOpen();
                  }
                 }}
              >
                Add
              </Typography>
            </Grid>
          </Grid>
          {/* End Investment fund header and open button*/}

          <Grid container item xs={12} md={10} lg={6}>
            <FieldArray
              name="ASPFundList"
              component={FundList}
              classes={classes}
              setDeleted={setNotSelected}
              checkFund={checkFund}
            />
          </Grid>

          {formValues.ASPFundList?.length > 0 && (
            <Grid container item xs={12} md={10} lg={6}>
              <Grid item xs={10} align="right">
                <Typography className={classes.totalInvestment}>
                  Total % investment :
                </Typography>
              </Grid>
              <Grid item xs={2} align="right">
                <Typography className={classes.totalPercent}>
                  {totalASPPercentage()}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" className={classes.error} align="right">
                  {formErrors.totalASPPercentage}
                </Typography>{' '}
              </Grid>
              <Grid item xs={12}>
                <br />
                {fundsBelongToNexusOrDever && (
                  <Typography variant="body1" className={classes.error}>
                    <FormattedMessage {...messages.fundError} />
                  </Typography>
                )}
              </Grid>

            </Grid>
          )}
        </>
      )}

     


      { /* Modal for selecting investment*/}
        <Modal
          open={open}
          onRendered={() => {
            setTimeout(() => {
              setAppLoading(false);
            }, 100);
          }}
        >
          <Grid component="div" className={classes.backgroundModal}>
            <Grid container className={classes.backgroundSearch}>
              <Grid item className={classes.search}>
                <IconButton onClick={handleSearch}>
                  <Search className={classes.icon} />
                </IconButton>
              </Grid>
              <Grid item className={classes.inputSearch}>
                <TextField
                  variant="outlined"
                  color="primary"
                  placeholder="Start typing…"
                  className={classes.customInput}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.colorBorder,
                      focused: classes.focused,
                    },
                  }}
                  fullWidth
                  // onKeyDown={e => {
                  //   keyPress(e);
                  // }}
                  onChange={e => {
                    setSearch(e.target.value);
                  }}
                />
              </Grid>
              <Grid item className={classes.search} align="right">
                <Close className={classes.icon} onClick={handleClose} />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={10}
              lg={6}
              className={classes.bodyModal}
            >
              <Grid item xs={3} className={classes.headerModal}>
                <Typography className={classes.textTitle}>Favourites</Typography>

              </Grid>
              <Grid item xs={9}>
                {
                  <React.Fragment>
                    <div className={classes.menuTabsOuter}>
                      <div className={classes.menuTabs}>
                        <Grid container spacing={1}>
                          {disabledFundList || disabledStrategyList ? (
                            <>
                              {!disabledFundList && (
                                <Grid item xs={12}>
                                  <Typography className={classes.textTitle2}>
                                    <FormattedMessage
                                      {...messages.showingAllAvailableFunds}
                                    />
                                  </Typography>
                                </Grid>
                              )}

                              {!disabledStrategyList && (
                                <Grid item xs={12}>
                                  <Typography className={classes.textTitle2}>
                                    <FormattedMessage
                                      {...messages.showingAllAvailableStrategies}
                                    />
                                  </Typography>
                                </Grid>
                              )}
                            </>
                          ) : (
                            <>
                              <Grid item xs={2}>
                                <Typography
                                  className={classes.textTitle2}
                                  onClick={() => {
                                    setShow('ALL');
                                  }}
                                  style={{
                                    textDecoration:
                                      show === 'ALL' ? 'none' : 'underline',
                                  }}
                                >
                                  <FormattedMessage {...messages.showAll} />
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography
                                  className={classes.textTitle2}
                                  onClick={() => {
                                    setShow('FUNDS');
                                  }}
                                  style={{
                                    textDecoration:
                                      show === 'FUNDS' ? 'none' : 'underline',
                                  }}
                                >
                                  <FormattedMessage {...messages.showAllFunds} />
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography
                                  className={classes.textTitle2}
                                  onClick={() => {
                                    setShow('STRATEGIES');
                                  }}
                                  style={{
                                    textDecoration:
                                      show === 'STRATEGIES'
                                        ? 'none'
                                        : 'underline',
                                  }}
                                >
                                  <FormattedMessage
                                    {...messages.showAllStrategies}
                                  />
                                </Typography>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </div>
                    </div>
                    {/* STRATEGY LIST */}
                    {show === 'STRATEGIES' || show === 'ALL' ? (
                      <>
                        {show === 'ALL' && (
                          <Typography className={classes.categoryTitle}>
                            <FormattedMessage {...messages.strategies} />
                          </Typography>
                        )}
                        {map(updatedStrategies(), (item, index) => {
                          return (
                            <Grid container item xs={12}>
                              <Grid
                                item
                                xs={11}
                                className={classes.box}
                                key={index}
                              >
                                <Grid item xs={6} className={classes.titleEuro}>
                                  {item.autoStartegyDesc}
                                </Grid>
                                <Grid item xs={2} align="center">
                                  {item.fundCcy}
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                  className={classes.eur}
                                  align="center"
                                >
                                  {item.autoStrategyCode}
                                </Grid>
                                <Grid item xs={1} align="right">
                                  <IconButton
                                    onClick={() => {
                                      handleFavourites(item);
                                      setIsStrategySelected(true);
                                    }}
                                  >
                                    <Add />
                                  </IconButton>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                align="right"
                                className={classes.iconHeart}
                              >
                                <IconButton>
                                  <Heart />
                                </IconButton>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </>
                    ) : (
                      ''
                    )}
                    {/* FUND LIST */}
                    {show === 'FUNDS' || show === 'ALL' ? (
                      <>
                        {show === 'ALL' && (
                          <Typography className={classes.categoryTitle}>
                            <FormattedMessage {...messages.funds} />
                          </Typography>
                        )}
                        {map(validFunds.funds, (item, index) => {
                          return (
                            <Grid container item xs={12}>
                              <Grid
                                item
                                xs={11}
                                className={classes.box}
                                key={index}
                              >
                                <Grid item xs={6} className={classes.titleEuro}>
                                  {item.fundDescription}
                                </Grid>
                                <Grid item xs={2} align="center">
                                  {item.fundCcy}
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                  className={classes.eur}
                                  align="center"
                                >
                                  {item.fundCode}
                                </Grid>
                                <Grid item xs={1} align="right">
                                  <IconButton
                                    onClick={() => handleFavourites(item)}
                                  >
                                    <Add />
                                  </IconButton>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                align="right"
                                className={classes.iconHeart}
                              >
                                <IconButton>
                                  <Heart />
                                </IconButton>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </>
                    ) : (
                      ''
                    )}
                  </React.Fragment>
                }
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      { /* End Modal for selecting investment*/}
    </Form>
  );
}

Investment.propTypes = {
  applicationId: PropTypes.string,
  updateApplicationFunds: PropTypes.func,
  fundsBelongToNexusOrDever: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  validFunds: makeSelectValidFunds(),
});

function mapDispatchToProps(dispatch) {
  return {
    setAppLoading: data => dispatch(setAppLoadingAction(data)),
    getValidFunds: data => dispatch(getValidFundssAction(data)),
    addFund: (fund, isASPFund) => {
      if(isASPFund){
        if (fund.fundCode) {
          dispatch(
            arrayPush('Investment', 'ASPFundList', { ...fund, percent: '0' }), 
          );
        } else {
          dispatch(
            arrayPush('Investment', 'ASPFundList', { ...fund, percent: '100' }), 
          );
        }
      } else {
        if (fund.fundCode) {
          dispatch(
            arrayPush('Investment', 'FundList', { ...fund, percent: '0' }),
          );
        } else {
          dispatch(
            arrayPush('Investment', 'FundList', { ...fund, percent: '100' }),
          );
        }
      }
    },
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withFormSelector = connect(state => {
  const formValues = getFormValues('Investment')(state);
  const formErrors = getFormSyncErrors('Investment')(state);

  return {
    formValues: !isEmpty(formValues) ? formValues.toJS() : {},
    formErrors: convertIfImmutable(formErrors),
  };
});

const withForm = reduxForm({
  form: 'Investment',
  enableReinitialize: true,
  //   onSubmit: submit,
  validate,
});

export default compose(withConnect, withFormSelector, withForm)(Investment);
